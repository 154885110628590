import React from "react";
import Pages from "../../pages/Pages";
import "../../Portfolio.css";

function Events() {
  return (
    <>
      <Pages folderName="events" title="Events" />
    </>
  );
}
export default Events;
