import React from "react";
import Pages from "../../pages/Pages";
import "../../Portfolio.css";

function Lifestyle() {
  return (
    <>
      <Pages folderName="lifestyle" title="Lifestyle" />
    </>
  );
}
export default Lifestyle;
