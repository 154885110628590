import React from "react";
import Pages from "../../pages/Pages";
import "../../Portfolio.css";

function Graduation() {
  return (
    <>
      <Pages folderName="graduation" title="Graduation" />
    </>
  );
}
export default Graduation;
